<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="ordersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openProcessWorkOrder"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n6">
                        <h1 class="mr-4">WORK ORDERS</h1>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateRangeText"
                                    label="Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :style="{ 'max-width': '250px' }"
                                    class="pt-1"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    :loading="loading"
                                    @click="loadData"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center" v-if="item.createdBy">
                    <p class="my-0">{{ item.createdBy.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center" v-if="item.project">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.productionStart`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            item.productionStart
                                ? formatDate(item.productionStart._seconds)
                                : ''
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.weight ? item.weight.toFixed(2) : 0 }} kg
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS WORK ORDER-->
        <v-dialog
            v-model="processWorkOrder"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessWorkOrder
                v-if="processWorkOrder"
                :order="selectedOrder"
                :readOnlyWO="true"
                @close="closeProcessWorkOrder"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import API from '@/services/api'
import SocketioService from '@/services/websocket/socket.service.js'
import _ from 'lodash'

export default {
    name: 'Closed',
    components: {
        ProcessWorkOrder: () =>
            import('@/components/WorkOrders/ProcessWorkOrder.vue'),
    },
    data: () => ({
        minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        dates: [],
        dateRanges: null,
        menu: false,
        statusToFilter: undefined,
        projectToFilter: undefined,
        loading: false,
        orders: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRODUCTION START',
                value: 'productionStart',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
        ],
        statusColor: {
            sent: '#FF9800',
            approved: '#27BC4F',
            production: '#FFC000',
            rejected: 'red',
            closed: 'primary',
            inProgress: '#9E9FA0',
            finished: '#5c5c5c',
        },
        projects: [],
        users: [],
        resourceId: undefined,
        processWorkOrder: false,
        selectedOrder: {},
    }),
    computed: {
        ...mapState(['notificationResource']),
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        ordersFiltered() {
            this.openResource()
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterStatus)
            }
            if (conditions.length > 0) {
                return this.orders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            return this.orders
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async created() {
        try {
            this.loading = true
            this.projects = await API.getLiteProjects({ all: true })
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            let currentDate = new Date()
            let date1 = new Date(currentDate)
            date1.setMonth(date1.getMonth() - 3)
            date1.setHours(0, 0, 0, 0)
            this.dates[0] = date1.toISOString().split('T')[0]
            this.dates[1] = currentDate.toISOString().split('T')[0]
            this.orders = await API.getCanceledWorkOrders({
                startDate: this.dates[0],
                endDate: this.dates[1],
            })
            for (const order of this.orders) {
                this.formatItems(order)
                order.createdBy = { id: order.createdBy }
                const user = this.users.find(
                    user => user.id == order.createdBy.id
                )
                if (user) {
                    order.createdBy.name = user.name
                }
                order.project = this.projects.find(
                    project => project.id == order.projectId
                )
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
            SocketioService.joinRoom(`${this.companyId}-WO`)
            SocketioService.leaveRoom(`${this.companyId}-BOM`)
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        async loadData() {
            try {
                this.loading = true
                let date1 = new Date(this.dates[0]).getTime()
                let date2 = new Date(this.dates[1]).getTime()
                if (date1 > date2) {
                    date1 = this.dates[1]
                    date2 = this.dates[0]
                } else {
                    date1 = this.dates[0]
                    date2 = this.dates[1]
                }
                this.orders = await API.getCanceledWorkOrders({
                    startDate: date1,
                    endDate: date2,
                })
                for (const order of this.orders) {
                    order.createdBy = { id: order.createdBy }
                    const user = this.users.find(
                        user => user.id == order.createdBy.id
                    )
                    order.createdBy.name = user.name
                    order.project = this.projects.find(
                        project => project.id == order.projectId
                    )
                }
                this.menu = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterStatus(order) {
            return order.status
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.workOrders.find(
                    w => w.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessWorkOrder(resource)
                }
            }
        },
        openProcessWorkOrder(item) {
            if (item.project) {
                this.selectedOrder = _.cloneDeep(item)
                this.processWorkOrder = true
            }
        },
        closeProcessWorkOrder() {
            this.processWorkOrder = false
        },
        formatItems(workOrder) {
            if (
                !(
                    (workOrder.status == 'closed' ||
                        workOrder.status == 'cancelled') &&
                    !workOrder.partNumbers
                )
            ) {
                delete workOrder.items
                workOrder.items = []
            }
            if (workOrder.partNumbers) {
                Object.keys(workOrder.partNumbers).forEach(partNumberId => {
                    let item = {
                        partNumberId,
                    }
                    Object.keys(workOrder.partNumbers[partNumberId]).forEach(
                        key => {
                            if (key == 'assemblyItems') {
                                let assemblyItems = []
                                Object.keys(
                                    workOrder.partNumbers[partNumberId][key]
                                ).forEach(aiKey => {
                                    assemblyItems.push({
                                        partNumberId: aiKey,
                                        ...workOrder.partNumbers[partNumberId][
                                            key
                                        ][aiKey],
                                    })
                                })
                                item[key] = assemblyItems
                            } else {
                                item[key] =
                                    workOrder.partNumbers[partNumberId][key]
                            }
                        }
                    )
                    workOrder.items.push(item)
                })
            }
            return workOrder
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
